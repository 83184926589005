body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.miw-100 {
  min-width: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.user-select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.overflow-scroll {
  -webkit-overflow-scrolling: touch;
}

.striped--near-white.hover-bg-light-gray:hover {
  background-color: #eee;
}

.active-row,
.striped--near-white.active-row,
.striped--near-white.active-row:hover {
  background-color: #cdecff;
}

